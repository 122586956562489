<template>
  <div class="tab-content col-lg-10 col-md-10 ">
    <div class="iq-card-body p-0">
      <div class="row">
        <!-- b-statements__aside -->
        <LeftMenu :menuId="3"></LeftMenu>
        <div class="col-lg-9">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('label.incomeStatistics')}}</h4>
            </template>
            <template v-slot:body>
              <div class="table-c">
                <div v-for="(item, index) in list" v-bind:key="index" class="b-report report-content mb-4 me-2">
                  <a class="btn btn-outline-primary btn-block" data-bs-toggle="collapse" :href="`#collapse-${index}`" role="button" aria-expanded="true" :aria-controls="`collapse-${index}`">
                    {{ item.date[0] }} ~ {{ item.date[1] }}
                    <i class="fa fa-angle-right me-2  float-end fs-4"></i>
                    <div class="d-flex justify-content-between">
                      <span>{{ item.earnings_report_title[0] }}</span>
                      <span>{{ item.earnings_report_title[1] }}</span>
                    </div>
                  </a>
                  <div class="collapse" :id="`collapse-${index}`">
                    <div class="collapse-content ms-2 me-2">
                      <div class="chart">
                        <div :id="`myEcharts-${index}`" :style="{ width: `${w}px`, height: '300px' }"></div>
                      </div>
                      <ul class="todo-task-list p-0 m-0">
                        <li class="">
                          <ul class="sub-task mt-2 p-0 show">
                            <li @click="toggleActive(0)" :class="selecded === 0 ? 'active' : ''">
                              <a>
                                <i class="ri-checkbox-blank-circle-fill text-success"></i>
                                {{$t('cat.subscriptions')}}
                              </a>
                              <span>{{ item.subscriptions[0] }}</span>
                              <span>{{ item.subscriptions[1] }}</span>
                            </li>
                            <li @click="toggleActive(1)" :class="selecded === 1 ? 'active' : ''">
                              <a><i class="ri-checkbox-blank-circle-fill text-warning"></i>
                                {{$t('cat.tips')}}</a>
                              <span>{{ item.tips[0] }}</span>
                              <span>{{ item.tips[1] }}</span>
                            </li>
                            <li @click="toggleActive(2)" :class="selecded === 2 ? 'active' : ''">
                              <a><i class="ri-checkbox-blank-circle-fill text-danger"></i>
                                {{$t('cat.posts')}}</a>
                              <span>{{ item.posts[0] }}</span>
                              <span>{{ item.posts[1] }}</span>
                            </li>
                            <li @click="toggleActive(3)" :class="selecded === 3 ? 'active' : ''">
                              <a><i class="ri-checkbox-blank-circle-fill text-primary"></i>
                                {{$t('cat.message')}}</a>
                              <span>{{ item.message[0] }}</span>
                              <span>{{ item.message[1] }}</span>
                            </li>
                            <li @click="toggleActive(4)" :class="selecded === 4 ? 'active' : ''">
                              <a><i class="ri-checkbox-blank-circle-fill text-info"></i>
                                {{$t('cat.suggest')}}</a>
                              <span class="me-3">{{ item.suggest[0] }}</span>
                              <span>{{ item.suggest[1] }}</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="footer">
                    <h6>{{$t('label.total')}}</h6>
                    <span>
                      {{$t('label.totalRevenue')}}
                      <span class="amount">{{item.total_revenue[0]}}</span>
                    </span>
                    <span>{{$t('label.net')}} <span class="amount">{{item.total_revenue[1]}}</span></span>
                  </div>
                </div>
              </div>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftMenu from '../User/Components/LeftMenu'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { getEarnings } from '@/api/balance'
import * as echarts from 'echarts'
import { onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
export default {
  name: 'UserReport',
  components: {
    LeftMenu
  },
  setup() {
    const { t } = useI18n()

    /// 声明定义一下echart
    let echart = echarts

    onMounted(() => {})
    onUnmounted(() => {
      echart.dispose
    })
    // 基础配置一下Echarts
    const initChart = (n, xAxisData, series) => {
      let chart = echart.init(document.getElementById('myEcharts-' + n))
      // 把配置和数据放这里
      chart.setOption({
        color: ['#2dcdb2', '#ffba68', '#ff9b8a', '#50b5ff', '#d592ff'],
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [
            t('cat.subscriptions'),
            t('cat.tips'),
            t('cat.posts'),
            t('cat.message'),
            t('cat.suggest')
          ]
        },
        yAxis: {
          type: 'value'
        },
        series: series
      })
    }

    return { initChart, t }
  },

  data() {
    return {
      w: '',
      primaryKey: 'created_at',
      commomData: commomData(),
      selecded: 0,

      list: []
    }
  },
  methods: {
    toggleActive(v) {
      this.selecded = v
    },
    async getRows() {
      this.commomData.listLoader = true
      this.commomData.condition.type = 'earningsReport'
      this.commomData.condition.order_by = this.primaryKey
      this.commomData.condition.start_date = '2022-03-27'
      this.commomData.condition.end_date = '2222-12-30'
      await getEarnings(this.commomData.condition)
        .then(res => {
          this.list = res.data
          this.commomData.condition.page = 2
          this.commomData.listLoader = false

          this.$nextTick(() => {
            this.list.forEach((item, index) => {
              let xAxisData = []
              let series = []
              xAxisData = item.xaxis_data
              series = [
                {
                  name: this.$t('cat.subscriptions'),
                  type: 'line',
                  smooth: true,
                  data: item.series_data.subscriptions,
                  lineStyle: { color: '#2dcdb2' }
                },
                {
                  name: this.$t('cat.tips'),
                  type: 'line',
                  smooth: true,
                  data: item.series_data.tips,
                  lineStyle: { color: '#ffba68' }
                },
                {
                  name: this.$t('cat.posts'),
                  type: 'line',
                  smooth: true,
                  data: item.series_data.posts,
                  lineStyle: { color: '#ff9b8a' }
                },
                {
                  name: this.$t('cat.message'),
                  type: 'line',
                  smooth: true,
                  data: item.series_data.message,
                  lineStyle: { color: '#50b5ff' }
                },
                {
                  name: this.$t('cat.suggest'),
                  type: 'line',
                  smooth: true,
                  data: item.series_data.suggest,
                  lineStyle: { color: '#d592ff' }
                }
              ]

              this.initChart(index, xAxisData, series)
            })
          })
        })
        .catch(err => {
          console.log(err)
          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            title: 'err.response.data.message'
          })
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  created() {
    this.getRows()
  },
  mounted() {
    this.$nextTick(() => {
      this.w = document.getElementsByClassName('table-c')[0].offsetWidth
    })
  }
}
</script>
